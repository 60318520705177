import { api } from "../../../api/api";
import {
    DocumentType,
    EnergyTypeResponse,
    NBOResponse,
    PortfolioResponse,
    PortfolioType,
    Project,
    ProjectCreateType,
    ProjectDetails,
    ProjectParams,
    ProjectStatusResponse,
    ProjectsResponse
} from "./types";

export const PROJECTS_LIST_TAG = "ProjectsList";
export const OWN_PROJECTS_LIST_TAG = "OwnProjectsList";
export const PROJECT_DETAILS_TAG = "ProjectsDetails";
export const PORTFOLIO_DETAILS_TAG = "PortfolioDetails";
export const OWN_PORTFOLIOS_LIST_TAG = "OwnPortfoliosList";
export const ENERGY_TYPE_LIST_TAG = "EnergyTypeList";
export const PORTFOLIOS_LIST_TAG = "PortfoliosList";
export const PENDING_NBO_TAG = "PendingNbo";
export const ACCEPTED_NBO_TAG = "AcceptedNbo";
export const DOCUMENTS_TAG = "Documents";

export const projectsApi = api
    .enhanceEndpoints({
        addTagTypes: [
            PROJECTS_LIST_TAG,
            OWN_PROJECTS_LIST_TAG,
            PROJECT_DETAILS_TAG,
            ENERGY_TYPE_LIST_TAG,
            PORTFOLIOS_LIST_TAG,
            OWN_PORTFOLIOS_LIST_TAG,
            PORTFOLIO_DETAILS_TAG,
            PENDING_NBO_TAG,
            ACCEPTED_NBO_TAG,
            DOCUMENTS_TAG
        ]
    })
    .injectEndpoints({
        endpoints: build => ({
            getProjectsList: build.query<ProjectsResponse, ProjectParams | void>({
                query: args => ({
                    method: "GET",
                    url: "projects/",
                    params: {
                        ...args
                    }
                }),
                providesTags: [PROJECTS_LIST_TAG]
            }),
            getPortfoliosList: build.query<PortfolioResponse, void>({
                query: () => ({
                    method: "GET",
                    url: "projects/portfolios/"
                }),
                providesTags: [PORTFOLIOS_LIST_TAG]
            }),
            getOwnPortfoliosList: build.query<PortfolioResponse, void>({
                query: () => ({
                    method: "GET",
                    url: "projects/portfolios/own/"
                }),
                providesTags: [OWN_PORTFOLIOS_LIST_TAG]
            }),
            getProjectsStatusChoices: build.query<ProjectStatusResponse, void>({
                query: () => ({
                    method: "GET",
                    url: "projects/status-choices/"
                })
            }),
            getCapacityRangeChoices: build.query<{ label: string; value: string }[], void>({
                query: () => ({
                    method: "GET",
                    url: "projects/capacity-choices/"
                })
            }),
            getOwnProjectsList: build.query<ProjectsResponse, void>({
                query: () => ({
                    method: "GET",
                    url: "projects/own/"
                }),
                providesTags: [OWN_PROJECTS_LIST_TAG]
            }),
            getProjectDetails: build.query<ProjectDetails, { slug: string }>({
                query: ({ slug }) => ({
                    method: "GET",
                    url: `projects/${slug}/`
                }),
                providesTags: [PROJECT_DETAILS_TAG]
            }),
            deleteProject: build.mutation<ProjectDetails, { slug: string }>({
                query: ({ slug }) => ({
                    method: "DELETE",
                    url: `projects/${slug}/`
                }),
                invalidatesTags: [OWN_PROJECTS_LIST_TAG, OWN_PORTFOLIOS_LIST_TAG]
            }),
            updateProject: build.mutation<ProjectDetails, { slug: string; body: ProjectCreateType }>({
                query: args => ({
                    body: {
                        // location: {
                        //     name: args.body.location,
                        //     country: args.body.country
                        // },
                        // name: args.body.name,
                        // description: args.body.description,
                        // status: args.body.status,
                        // capacity: args.body.capacity,
                        // target_amount: args.body.target_amount,
                        // min_investment: args.body.min_investment,
                        // deadline_years: args.body.deadline_years,
                        // yield_to_maturity: args.body.yield_to_maturity,
                        // energy_type: args.body.energy_type,
                        // portfolio: args.body.portfolio
                        ...args.body
                    },
                    method: "PATCH",
                    url: `projects/${args.slug}/`
                }),
                invalidatesTags: [OWN_PROJECTS_LIST_TAG, PROJECT_DETAILS_TAG, OWN_PORTFOLIOS_LIST_TAG, PORTFOLIO_DETAILS_TAG]
            }),
            getPortfolioDetails: build.query<PortfolioType, { id: string }>({
                query: ({ id }) => ({
                    method: "GET",
                    url: "projects/portfolios/" + id
                }),
                providesTags: [PORTFOLIO_DETAILS_TAG]
            }),
            getEnergyTypes: build.query<EnergyTypeResponse, void>({
                query: () => ({
                    method: "GET",
                    url: "projects/energy-types/"
                }),
                providesTags: [ENERGY_TYPE_LIST_TAG]
            }),
            createProjects: build.mutation<Project, { formData: FormData }>({
                query: ({ formData }) => ({
                    body: formData,
                    method: "POST",
                    url: "projects/"
                }),
                invalidatesTags: args => (args?.portfolio ? [OWN_PORTFOLIOS_LIST_TAG] : [OWN_PORTFOLIOS_LIST_TAG, OWN_PROJECTS_LIST_TAG])
            }),
            createPortfolio: build.mutation<PortfolioType, { name: string; description: string }>({
                query: args => ({
                    body: { ...args },
                    method: "POST",
                    url: "projects/portfolios/"
                }),
                invalidatesTags: [OWN_PORTFOLIOS_LIST_TAG, PORTFOLIO_DETAILS_TAG]
            }),
            addDocument: build.mutation<Project, { projectSlug: string; file: FormData }>({
                query: args => {
                    return {
                        url: "projects/" + args.projectSlug + "/documents/",
                        method: "POST",
                        body: args.file
                    };
                },
                invalidatesTags: [PROJECTS_LIST_TAG, DOCUMENTS_TAG]
            }),

            deleteDocument: build.mutation<Project, { projectSlug: string; documentId: number }>({
                query: args => {
                    return {
                        url: `projects/${args.projectSlug}/documents/${args.documentId}/`,
                        method: "DELETE"
                    };
                },
                invalidatesTags: [PROJECTS_LIST_TAG, PROJECT_DETAILS_TAG, DOCUMENTS_TAG]
            }),
            updateDocumentName: build.mutation<Project, { projectSlug: string; documentId: number; title: string }>({
                query: args => {
                    return {
                        url: `projects/${args.projectSlug}/documents/${args.documentId}/`,
                        method: "PATCH",
                        body: { new_title: args.title }
                    };
                },
                invalidatesTags: [PROJECTS_LIST_TAG, PROJECT_DETAILS_TAG, DOCUMENTS_TAG]
            }),
            getDocumentsForProject: build.query<{ results: DocumentType[] }, { projectSlug: string }>({
                query: ({ projectSlug }) => {
                    return {
                        url: "projects/" + projectSlug + "/documents/",
                        method: "GET"
                    };
                },
                providesTags: [DOCUMENTS_TAG]
            }),

            getPendingNbos: build.query<NBOResponse, void>({
                query: () => {
                    return {
                        url: "projects/nbos/pending",
                        method: "GET"
                    };
                },
                providesTags: [PENDING_NBO_TAG]
            }),
            getAcceptedNbos: build.query<NBOResponse, void>({
                query: () => {
                    return {
                        url: "projects/nbos/accepted",
                        method: "GET"
                    };
                },
                providesTags: [PENDING_NBO_TAG]
            })
        })
    });

export const {
    useGetProjectsListQuery,
    useGetEnergyTypesQuery,
    useCreateProjectsMutation,
    useGetProjectDetailsQuery,
    useGetOwnProjectsListQuery,
    useAddDocumentMutation,
    useGetProjectsStatusChoicesQuery,
    useCreatePortfolioMutation,
    useGetOwnPortfoliosListQuery,
    useGetPortfoliosListQuery,
    useGetPortfolioDetailsQuery,
    useGetAcceptedNbosQuery,
    useGetPendingNbosQuery,
    useDeleteProjectMutation,
    useUpdateProjectMutation,
    useGetDocumentsForProjectQuery,
    useDeleteDocumentMutation,
    useUpdateDocumentNameMutation,
    useGetCapacityRangeChoicesQuery
} = projectsApi;
