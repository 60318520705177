import { Box, Button, Flex, HStack, Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import logo from "../../../assets/images/logo.png";

export const Header = () => {
    const navbarOptions = [
        {
            text: "Solution",
            link: "#solution"
        },
        {
            text: "Our Essence",
            link: "#essence"
        },
        {
            text: "Integration",
            link: "#integration"
        },
        {
            text: "Showcase",
            link: "#showcase"
        }
    ];
    return (
        <Flex position='sticky' top='0' p='1rem' left='0' right='0' zIndex='10' bgColor='white' w='full'>
            <HStack w='68rem' mx='auto' justifyContent='space-between' alignItems='center'>
                <Box w={{ base: "50%", md: "25%" }}>
                    <Image src={logo} alt='logo' w='3rem' />
                </Box>
                <Flex gap='1.2rem' display={{ base: "none", md: "flex" }}>
                    {navbarOptions.map((option, index) => (
                        <Button key={index} as={"a"} href={option.link} variant='link' color='gray.500' _hover={{ color: "green.400" }}>
                            {option.text}
                        </Button>
                    ))}
                </Flex>
                <Flex gap='1rem' w={{ base: "50%", md: "25%" }} justifyContent='flex-end'>
                    <Button as={Link} variant='outline' to='/login' size='sm'>
                        Sign in
                    </Button>
                    <Button size='sm' as={Link} to='/book-demo'>
                        Book a demo
                    </Button>
                </Flex>
            </HStack>
        </Flex>
    );
};
