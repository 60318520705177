import { CheckIcon } from "@chakra-ui/icons";
import {
    Button,
    FormControl,
    HStack,
    Input,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    Textarea,
    VStack,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { EditIcon } from "../assets/icons/edit";
import { CustomModal } from "../components/CustomModal";
import { useCountrySelect } from "../hooks/useCountrySelect";
import { useEnergyTypeSelect } from "../hooks/useEnergyTypeSelect";
import { useStatusSelect } from "../hooks/useStatusSelect";
import {
    useAddDocumentMutation,
    useDeleteDocumentMutation,
    useDeleteProjectMutation,
    useGetDocumentsForProjectQuery,
    useUpdateDocumentNameMutation,
    useUpdateProjectMutation
} from "../modules/projects/api/projectsApi";
import { Project, ProjectCreateType } from "../modules/projects/api/types";

type ContextType = {
    onDeletePress: (project: Project) => void;
    onEditPress: (project: Project) => void;
};

export const EditProjectContext = createContext<ContextType>({
    onDeletePress: () => null,
    onEditPress: () => null
});

export const useEditProjectContext = () => {
    const context = useContext(EditProjectContext);

    if (context === undefined) {
        throw new Error("EditProjectContext was used outside of its Provider");
    }

    return context;
};

export const EditProjectProvider = ({ children }: { children: React.ReactNode }) => {
    const toast = useToast();
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedProject, setSelectedProject] = useState<Project>();
    const [deleteProject, { isLoading: loadingDelete }] = useDeleteProjectMutation();
    const [updateProject, { isLoading: loadingUpdate }] = useUpdateProjectMutation();
    const [mode, setMode] = useState<"delete" | "edit" | null>(null);
    const [deleteDocs, setDeletedDocs] = useState<number[]>([]);
    const [docNames, setDocNames] = useState<string[]>([]); // State for existing document names
    const [files, setFiles] = useState<File[]>([]); // State for new files added
    const [editDocIndex, setEditDocIndex] = useState<{
        index: number | null;
        type: "current" | "new";
    }>({
        index: null,
        type: "current"
    });

    const { data: documents } = useGetDocumentsForProjectQuery(
        {
            projectSlug: selectedProject?.slug || ""
        },
        { skip: !selectedProject }
    );

    const [deleteDocument, { isLoading: loadingDeleteDoc }] = useDeleteDocumentMutation();
    const [addDocument, { isLoading: loadingAddDoc }] = useAddDocumentMutation();
    const [updateDocName, { isLoading: loadingUpdateDocName }] = useUpdateDocumentNameMutation();

    const { EnergyTypeSelect, value: energyTypeId } = useEnergyTypeSelect(selectedProject?.energy_type.id);
    const { ProjectStatusSelect, value: statusValue } = useStatusSelect(selectedProject?.status.id);
    const { CountrySelect, value: countryId } = useCountrySelect(selectedProject?.location.country.id);

    const { register, handleSubmit, setValue } = useForm<ProjectCreateType>();

    const onDeleteConfirm = async () => {
        if (!selectedProject) return;
        try {
            await deleteProject({ slug: selectedProject.slug }).unwrap();
            toast({
                title: "Project deleted",
                description: "The project has been successfully deleted.",
                status: "success",
                duration: 3000
            });
        } catch (err) {
            toast({
                title: "Project not deleted",
                description: "An error occurred while deleting the project.",
                status: "error",
                duration: 3000
            });
        }
        onCloseEdit();
    };

    const onEditConfirm: SubmitHandler<ProjectCreateType> = async data => {
        if (!selectedProject) return;

        try {
            await updateProject({ slug: selectedProject.slug, body: data }).unwrap();

            // delete docs
            if (deleteDocs.length) {
                await Promise.all(
                    deleteDocs.map(id =>
                        deleteDocument({
                            projectSlug: selectedProject.slug,
                            documentId: id
                        })
                            .unwrap()
                            .catch(err => {
                                return err;
                            })
                    )
                );
            }
            // add new docs
            if (files.length) {
                const formData = new FormData();
                files.forEach(file => {
                    formData.append("documents", file);
                });
                addDocument({
                    projectSlug: selectedProject.slug,
                    file: formData
                });
            }
            // update doc names
            await Promise.all(
                docNames.map((name, index) => {
                    if (documents?.results[index].title !== name) {
                        updateDocName({
                            projectSlug: selectedProject.slug,
                            documentId: documents?.results[index].id || 0,
                            title: name
                        })
                            .unwrap()
                            .catch(err => {
                                console.error(err);
                            });
                    }
                })
            );

            toast({
                title: "Project updated",
                description: "The project has been successfully updated.",
                status: "success",
                duration: 3000
            });
        } catch (err) {
            toast({
                title: "Project not updated",
                description: "An error occurred while updating the project.",
                status: "error",
                duration: 3000
            });
        }
        onCloseEdit();
    };

    const onDeletePress = (project: Project) => {
        setSelectedProject(project);
        setMode("delete");
        onOpen();
    };

    const onEditPress = (project: Project) => {
        setSelectedProject(project);
        setMode("edit");
        onOpen();
    };

    const onEditDocNamePress = ({ index, type }: { index: number; type: "current" | "new" }) => {
        if (editDocIndex.index === index) {
            setEditDocIndex({ index: null, type: "current" });
            return;
        }
        setEditDocIndex({ index, type });
    };

    const onCloseEdit = () => {
        onClose();
        setSelectedProject(undefined);
        setMode(null);
        setDeletedDocs([]);
        setFiles([]);
    };

    useEffect(() => {
        if (mode === "edit" && selectedProject) {
            setValue("name", selectedProject.name);
            setValue("description", selectedProject.description);
            setValue("capacity", selectedProject?.capacity || 0);
            setValue("location", selectedProject.location.name);
            setValue("country", selectedProject.location.country.id);
            setValue("target_amount", selectedProject?.target_amount || 0);
            setValue("target_amount", selectedProject?.target_amount || 0);
            setValue("min_investment", selectedProject?.min_investment || 0);
            setValue("yield_to_maturity", selectedProject?.yield_to_maturity || 0);
            setValue("deadline_years", selectedProject.deadline_years as unknown as number);
        }
    }, [selectedProject, mode, setValue]);

    useEffect(() => {
        if (mode === "edit" && energyTypeId) {
            setValue("energy_type", energyTypeId);
        }
        if (mode === "edit" && statusValue) {
            console.log("statusValue", statusValue);
            setValue("status", statusValue);
        }
        if (mode === "edit" && countryId) {
            setValue("country", countryId);
        }
    }, [energyTypeId, statusValue, countryId, mode, setValue]);

    useEffect(() => {
        if (documents) {
            setDocNames(documents.results.map(doc => doc.title));
        }
    }, [documents]);

    const handleFilesChange: React.ChangeEventHandler<HTMLInputElement> = event => {
        const filesUploaded = event.target.files;

        if (filesUploaded) {
            setFiles(prev => [...prev, ...Array.from(filesUploaded).map(file => file)]);
        }
    };

    return (
        <EditProjectContext.Provider
            value={{
                onDeletePress,
                onEditPress
            }}>
            {children}
            {Boolean(mode) && (
                <CustomModal
                    isOpen={isOpen}
                    onClose={onCloseEdit}
                    title={mode === "delete" ? "Delete project" : "Edit project"}
                    confirmText={mode === "delete" ? "Delete" : "Save"}
                    cancelText='Cancel'
                    onConfirm={mode === "delete" ? onDeleteConfirm : handleSubmit(onEditConfirm)}
                    isLoading={loadingDelete || loadingUpdate || loadingDeleteDoc || loadingAddDoc || loadingUpdateDocName}>
                    {mode === "delete" ? (
                        <Text>Are you sure you want to delete this project?</Text>
                    ) : (
                        <form
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "1rem",
                                maxHeight: "30rem",
                                overflowY: "auto"
                            }}>
                            <Tabs colorScheme='green'>
                                <TabList>
                                    <Tab>Details</Tab>
                                    <Tab>Documents</Tab>
                                </TabList>
                                <TabPanels>
                                    <TabPanel px='0'>
                                        <FormControl>
                                            <Text fontSize='xs' fontWeight='bold' mb='1'>
                                                Name
                                            </Text>
                                            <Input
                                                {...register("name", {
                                                    required: "This field is required",
                                                    minLength: {
                                                        value: 4,
                                                        message: "Minimum length should be 4"
                                                    }
                                                })}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <Text fontSize='xs' fontWeight='bold' mb='1'>
                                                Description
                                            </Text>
                                            <Textarea
                                                {...register("description", {
                                                    required: "This field is required",
                                                    minLength: {
                                                        value: 4,
                                                        message: "Minimum length should be 4"
                                                    }
                                                })}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <Text fontSize='xs' fontWeight='bold' mb='1'>
                                                Energy type
                                            </Text>
                                            {EnergyTypeSelect}
                                        </FormControl>
                                        <FormControl>
                                            <Text fontSize='xs' fontWeight='bold' mb='1'>
                                                Status
                                            </Text>
                                            {ProjectStatusSelect}
                                        </FormControl>
                                        <FormControl>
                                            <Text fontSize='xs' fontWeight='bold' mb='1'>
                                                Capacity
                                            </Text>
                                            <Input
                                                type='number'
                                                {...register("capacity", {
                                                    required: "This field is required",
                                                    minLength: {
                                                        value: 1,
                                                        message: "Minimum length should be 1"
                                                    }
                                                })}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <Text fontSize='xs' fontWeight='bold' mb='1'>
                                                Location
                                            </Text>
                                            <Input
                                                {...register("location", {
                                                    required: "This field is required",
                                                    minLength: {
                                                        value: 4,
                                                        message: "Minimum length should be 4"
                                                    }
                                                })}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <Text fontSize='xs' fontWeight='bold' mb='1'>
                                                Country
                                            </Text>
                                            {CountrySelect}
                                        </FormControl>
                                        <FormControl>
                                            <Text fontSize='xs' fontWeight='bold' mb='1'>
                                                Target amount
                                            </Text>
                                            <Input
                                                type='number'
                                                {...register("target_amount", {
                                                    required: "This field is required",
                                                    minLength: {
                                                        value: 1,
                                                        message: "Minimum length should be 1"
                                                    }
                                                })}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <Text fontSize='xs' fontWeight='bold' mb='1'>
                                                Minimum investment
                                            </Text>
                                            <Input
                                                type='number'
                                                {...register("min_investment", {
                                                    required: "This field is required",
                                                    minLength: {
                                                        value: 1,
                                                        message: "Minimum length should be 1"
                                                    }
                                                })}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <Text fontSize='xs' fontWeight='bold' mb='1'>
                                                Yield
                                            </Text>
                                            <Input
                                                type='number'
                                                {...register("yield_to_maturity", {
                                                    required: "This field is required",
                                                    minLength: {
                                                        value: 1,
                                                        message: "Minimum length should be 1"
                                                    }
                                                })}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <Text fontSize='xs' fontWeight='bold' mb='1'>
                                                Development maturity
                                            </Text>
                                            <Input
                                                size='md'
                                                type='number'
                                                {...register("deadline_years", {
                                                    required: "This field is required",
                                                    minLength: {
                                                        value: 1,
                                                        message: "Minimum length should be 1"
                                                    }
                                                })}
                                            />
                                        </FormControl>
                                    </TabPanel>
                                    <TabPanel px='0'>
                                        <VStack spacing='.5rem' alignItems='flex-start'>
                                            {documents?.results
                                                .filter(({ id }) => !deleteDocs.includes(id))
                                                .map((document, index) => (
                                                    <HStack justifyContent='space-between' w='full' key={document.id}>
                                                        <HStack spacing='0'>
                                                            {index === editDocIndex.index && editDocIndex.type === "current" ? (
                                                                <Input
                                                                    rounded='lg'
                                                                    size='sm'
                                                                    key={document.title}
                                                                    value={docNames[index]}
                                                                    onChange={e => {
                                                                        setDocNames(prev =>
                                                                            prev.map((name, i) => (i === index ? e.target.value : name))
                                                                        );
                                                                    }}
                                                                />
                                                            ) : (
                                                                <Text fontSize='sm' key={document.id}>
                                                                    {docNames[index]}
                                                                </Text>
                                                            )}
                                                            <Button
                                                                variant='ghost'
                                                                size='xs'
                                                                onClick={() => onEditDocNamePress({ index, type: "current" })}>
                                                                {index === editDocIndex.index && editDocIndex.type === "current" ? (
                                                                    <CheckIcon />
                                                                ) : (
                                                                    <EditIcon size={15} />
                                                                )}
                                                            </Button>
                                                        </HStack>
                                                        <HStack>
                                                            <Button as={"a"} href={document.file} target='_blank' size='xs'>
                                                                Preview
                                                            </Button>
                                                            <Button
                                                                size='xs'
                                                                colorScheme='red'
                                                                onClick={() => setDeletedDocs(prev => [...prev, document.id])}>
                                                                Delete
                                                            </Button>
                                                        </HStack>
                                                    </HStack>
                                                ))}
                                            {files.map((file, index) => (
                                                <HStack justifyContent='space-between' w='full' key={file.name}>
                                                    <HStack spacing='0'>
                                                        {index === editDocIndex.index && editDocIndex.type === "new" ? (
                                                            <Input
                                                                rounded='lg'
                                                                size='sm'
                                                                key={file.name}
                                                                value={files[index].name}
                                                                onChange={e => {
                                                                    setFiles(prev => {
                                                                        const newFiles = [...prev];
                                                                        const doc = { ...newFiles[index], name: e.target.value };
                                                                        newFiles[index] = new File([doc], doc.name);
                                                                        return newFiles;
                                                                    });
                                                                }}
                                                            />
                                                        ) : (
                                                            <Text key={file.name} fontSize='sm'>
                                                                {files[index].name}
                                                            </Text>
                                                        )}
                                                        <Button variant='ghost' size='xs' onClick={() => onEditDocNamePress({ index, type: "new" })}>
                                                            {index === editDocIndex.index && editDocIndex.type === "new" ? (
                                                                <CheckIcon />
                                                            ) : (
                                                                <EditIcon size={15} />
                                                            )}
                                                        </Button>
                                                    </HStack>
                                                    <Button
                                                        size='xs'
                                                        colorScheme='red'
                                                        onClick={() => {
                                                            setFiles(prev => prev.filter(f => f.name !== file.name));
                                                        }}>
                                                        Delete
                                                    </Button>
                                                </HStack>
                                            ))}
                                            <Button size='xs' onClick={() => hiddenFileInput.current?.click()}>
                                                Add file
                                            </Button>
                                            <input
                                                ref={hiddenFileInput}
                                                style={{ display: "none" }}
                                                type='file'
                                                multiple
                                                onChange={handleFilesChange}
                                            />
                                        </VStack>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </form>
                    )}
                </CustomModal>
            )}
        </EditProjectContext.Provider>
    );
};
