import { FormControl, FormErrorMessage, FormLabel, Text, VStack, useToast } from "@chakra-ui/react";
import { MultiSelect, Option, SelectionVisibilityMode } from "chakra-multiselect";
import { SubmitHandler, useForm } from "react-hook-form";

import { CustomModal } from "../../../components/CustomModal";
import { useGetCountriesQuery } from "../../geography/api/geographyApi";
import { useUpdateProfileMutation } from "../../home/api/usersApi";
import { useGetCapacityRangeChoicesQuery, useGetEnergyTypesQuery, useGetProjectsStatusChoicesQuery } from "../../projects/api/projectsApi";
import "./profile.css";

type ProfileInputs = {
    regionFocus: Option[];
    statusFocus: Option[];
    energyTypeFocus: Option[];
    capacity_range_focus: string;
};

type ProfileUpdateModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const ProfileUpdateModal = ({ isOpen, onClose }: ProfileUpdateModalProps) => {
    const toast = useToast();
    const [updateProfile, { isLoading }] = useUpdateProfileMutation();
    const { data: countries } = useGetCountriesQuery();
    const { data: projectStatus } = useGetProjectsStatusChoicesQuery();
    const { data: energyTypes } = useGetEnergyTypesQuery();
    const { data: capacityRange } = useGetCapacityRangeChoicesQuery();
    const {
        handleSubmit,
        setError,
        clearErrors,
        watch,
        setValue,
        formState: { errors }
    } = useForm<ProfileInputs>({
        defaultValues: {
            regionFocus: [],
            statusFocus: [],
            energyTypeFocus: [],
            capacity_range_focus: ""
        }
    });

    const countryOptions: Option[] = countries ? countries.map(country => ({ label: country.name, value: String(country.id) })) : [];
    const energyTypeOptions: Option[] = energyTypes
        ? energyTypes.results.map(energyType => ({ label: energyType.name, value: String(energyType.id) }))
        : [];
    const statusOptions: Option[] = projectStatus ? projectStatus.results.map(status => ({ label: status.name, value: String(status.id) })) : [];

    const onSubmit: SubmitHandler<ProfileInputs> = async data => {
        if (!data.regionFocus.length || !data.statusFocus.length || !data.energyTypeFocus.length || !data.capacity_range_focus) {
            if (!data.regionFocus.length) {
                setError("regionFocus", { message: "Please select at least one country" });
            }
            if (!data.statusFocus.length) {
                setError("statusFocus", { message: "Please select at least one status" });
            }
            if (!data.energyTypeFocus.length) {
                setError("energyTypeFocus", { message: "Please select at least one energy type" });
            }
            if (!data.capacity_range_focus) {
                setError("capacity_range_focus", { message: "Please select a capacity range" });
            }
            return;
        }

        try {
            await updateProfile({
                region_focus: data.regionFocus.map(option => Number(option.value)),
                status_focus: data.statusFocus.map(option => String(option.value)),
                energy_type_focus: data.energyTypeFocus.map(option => Number(option.value)),
                capacity_range_focus: data.capacity_range_focus
            }).unwrap();
            onClose();
            toast({
                title: "Success!",
                description: "Preferences updated successfully",
                status: "success",
                duration: 3000
            });
        } catch (err) {
            toast({
                title: "Error!",
                description: "Something went wrong",
                status: "error",
                duration: 3000
            });
        }
    };

    const onRemindLater = () => {
        onClose();
        const nextDay = new Date().getTime() + 1000 * 60 * 60 * 24;
        const valueToStore = JSON.stringify({
            value: true,
            expiry: nextDay
        });
        localStorage.setItem("remindLater", valueToStore);
    };

    return (
        <CustomModal
            isOpen={isOpen}
            onClose={onClose}
            title={"Preferences"}
            confirmText={"Save"}
            cancelText='Remind me later'
            onConfirm={() => {
                clearErrors();
                handleSubmit(onSubmit)();
            }}
            onCancel={onRemindLater}
            isLoading={isLoading}>
            <VStack w='full'>
                <Text fontSize='sm' textAlign='start'>
                    To tailor your experience, please update your preferences.
                </Text>
                <VStack gap='2rem' w='full'>
                    <FormControl isInvalid={Boolean(errors.regionFocus)}>
                        <FormLabel>Which countries are you focused on?</FormLabel>
                        <MultiSelect
                            _placeholder={{ color: "gray.400" }}
                            options={countryOptions}
                            value={watch("regionFocus")}
                            onChange={val => setValue("regionFocus", val as Option[])}
                            size='md'
                            id='regionFocus'
                            selectionVisibleIn={SelectionVisibilityMode.Both}
                            filterFn={(option, query) => {
                                if (typeof option === "string") {
                                    return [];
                                } else {
                                    const searchValue = query as string;
                                    return option.filter(o => o.label.toLowerCase().includes(searchValue.toLowerCase()));
                                }
                            }}
                        />
                        <FormErrorMessage>{errors.regionFocus && errors.regionFocus.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={Boolean(errors.statusFocus)}>
                        <FormLabel>What project stages are you interested in?</FormLabel>
                        <MultiSelect
                            id='statusFocus'
                            options={statusOptions}
                            value={watch("statusFocus")}
                            onChange={val => setValue("statusFocus", val as Option[])}
                            size='md'
                            selectionVisibleIn={SelectionVisibilityMode.Both}
                        />
                        <FormErrorMessage>{errors.statusFocus && errors.statusFocus.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={Boolean(errors.energyTypeFocus)}>
                        <FormLabel>What energy types are relevant to you?</FormLabel>
                        <MultiSelect
                            options={energyTypeOptions}
                            value={watch("energyTypeFocus")}
                            onChange={val => setValue("energyTypeFocus", val as Option[])}
                            size='md'
                            selectionVisibleIn={SelectionVisibilityMode.Both}
                        />
                        <FormErrorMessage>{errors.energyTypeFocus && errors.energyTypeFocus.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={Boolean(errors.capacity_range_focus)}>
                        <FormLabel>What project capacity range are you looking for?</FormLabel>
                        <MultiSelect
                            options={capacityRange || []}
                            value={watch("capacity_range_focus")}
                            onChange={val => {
                                // @ts-ignore
                                setValue("capacity_range_focus", val);
                            }}
                            size='md'
                            selectionVisibleIn={SelectionVisibilityMode.Both}
                            single
                        />
                        <FormErrorMessage>{Boolean(errors.capacity_range_focus && errors.capacity_range_focus.message)}</FormErrorMessage>
                    </FormControl>
                </VStack>
            </VStack>
        </CustomModal>
    );
};
