import { Avatar, Button, HStack, Heading, Input, Text, Textarea, VStack, useToast } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

import { useGetUserProfileQuery, useUpdateProfileMutation } from "../../home/api/usersApi";

export const UserDetails = () => {
    const [editMode, setEditMode] = useState(false);
    const toast = useToast();

    const { data: profile } = useGetUserProfileQuery();
    const [updateProfile, { isLoading: loadingUpdate }] = useUpdateProfileMutation();

    const [uploadedImage, setUploadedImage] = useState<File | null>(null);
    const [deleteAvatar, setDeleteAvatar] = useState(false);
    const [values, setValues] = useState({
        avatar: profile?.avatar,
        first_name: profile?.first_name,
        last_name: profile?.last_name,
        email: profile?.email,
        position: profile?.position,
        bio: profile?.bio
    });

    const inputFile = useRef<HTMLInputElement>(null);

    const onInputClick = () => {
        console.log("clicked");

        inputFile.current?.click();
    };

    const onProfileUpdate = async () => {
        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
            if (value) {
                if (key === "avatar") return;
                console.log("key", key, "value", value);
                formData.append(key, value);
            }
        });
        if (uploadedImage) {
            formData.append("avatar", uploadedImage);
        }

        if (deleteAvatar) {
            formData.append("delete_avatar", "true");
        }

        try {
            await updateProfile(formData).unwrap();
            setEditMode(false);
            toast({
                title: "Profile updated",
                status: "success",
                duration: 3000,
                isClosable: true
            });
        } catch (err) {
            toast({
                title: "Failed to update profile",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return err;
        }
    };

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setUploadedImage(file);
            const reader = new FileReader();
            reader.onload = () => {
                setValues({ ...values, avatar: reader.result as string });
            };
            reader.readAsDataURL(file);
        }
    };

    const profileFields = [
        {
            title: "Profile picture",
            content: (
                <HStack gap='4'>
                    {editMode ? <Avatar size='xl' src={values.avatar} bg='green.400' /> : <Avatar size='xl' src={profile?.avatar} bg='green.400' />}
                    {editMode && (
                        <HStack>
                            <input type='file' id='file' ref={inputFile} style={{ display: "none" }} onChange={handleChange} />
                            <Button size='sm' onClick={onInputClick}>
                                Change picture
                            </Button>
                            <Button
                                size='sm'
                                variant='outline'
                                colorScheme='red'
                                onClick={() => {
                                    setValues({ ...values, avatar: "" });
                                    setDeleteAvatar(true);
                                }}>
                                Delete picture
                            </Button>
                        </HStack>
                    )}
                </HStack>
            )
        },
        {
            title: "Email",
            content: (
                <VStack spacing='1' alignItems='flex-start' w='full'>
                    {<Text>{profile?.email}</Text>}
                </VStack>
            )
        },
        {
            title: "Name",
            content: (
                <VStack alignItems='flex-start' w='full'>
                    <HStack w='full'>
                        {editMode ? (
                            <Input placeholder='First name' value={values.first_name} name='first_name' onChange={onInputChange} />
                        ) : (
                            <Text>{profile?.first_name}</Text>
                        )}
                        {editMode ? (
                            <Input placeholder='Last name' value={values.last_name} name='last_name' onChange={onInputChange} />
                        ) : (
                            <Text>{profile?.last_name}</Text>
                        )}
                    </HStack>
                </VStack>
            )
        },
        {
            title: "Job title",
            content: (
                <VStack spacing='1' alignItems='flex-start' w='full'>
                    {editMode ? (
                        <Input placeholder='Job title' value={values?.position} flexGrow='1' resize='none' name='position' onChange={onInputChange} />
                    ) : (
                        <Text>{profile?.position}</Text>
                    )}
                </VStack>
            )
        },

        {
            title: "Headline",
            content: (
                <VStack spacing='1' alignItems='flex-start' w='full'>
                    {editMode ? (
                        <Textarea placeholder='About me' value={values?.bio} flexGrow='1' resize='none' name='bio' onChange={onInputChange} />
                    ) : (
                        <Text>{profile?.bio || "-"}</Text>
                    )}
                </VStack>
            )
        }
    ];

    useEffect(() => {
        if (profile?.avatar) {
            setValues({ ...values, avatar: profile.avatar });
        }
    }, [profile?.avatar]);
    return (
        <VStack h='full' w='full'>
            <VStack gap='4' h='full' w='full'>
                {profileFields.map(({ title, content }) => (
                    <VStack key={title} w='100%' gap='2' alignItems='flex-start'>
                        <Heading size='sm'>{title}</Heading>
                        {content}
                    </VStack>
                ))}
            </VStack>
            {editMode ? (
                <HStack w='full'>
                    <Button colorScheme='red' onClick={() => setEditMode(false)} flexGrow='1'>
                        Cancel
                    </Button>
                    <Button colorScheme='green' flexGrow='1' onClick={onProfileUpdate} isLoading={loadingUpdate}>
                        Save
                    </Button>
                </HStack>
            ) : (
                <Button w='full' onClick={() => setEditMode(true)}>
                    Edit
                </Button>
            )}
        </VStack>
    );
};

