import { SimpleGrid, Text, VStack } from "@chakra-ui/react";

type GridCardsProps = {
    children: React.ReactNode;
    isEmpty?: boolean;
    emptyString?: string;
};

export const GridCards = ({ children, isEmpty = false, emptyString }: GridCardsProps) => {
    return isEmpty ? (
        <VStack spacing='1rem' alignItems='flex-start'>
            <Text>{emptyString || "No data to show"}</Text>
        </VStack>
    ) : (
        <SimpleGrid columns={[1, 2, 3, 4]} spacing='1rem' w='full'>
            {children}
        </SimpleGrid>
    );
};
